window.addEventListener('DOMContentLoaded', function() {
  // if a link in a modal opens another modal, close the current modal
  $(document).on('click', '.modal *[data-toggle="modal"]', function(event) {
    $(event.target).closest('.modal').modal('hide');
    if (event.target.dataset.targetPicker && (event.target.dataset.targetPickerSelected || event.target.dataset.targetPickerValue)) {
      event.target.dispatchEvent(new CustomEvent("select_on_open", {
        bubbles: true,
        cancelable: true,
        detail: {picker: event.target.dataset.targetPicker, value: event.target.dataset.targetPickerValue}
      }));
    }
  });

  // if a link in a modal opens the eliza modal, close the current modal
  $(document).on('click', '.modal *[data-toggle="eliza"]', function(event) {
    $(event.target).closest('.modal').modal('hide');
  });
});
