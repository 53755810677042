window.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('select_on_open', (event) => {
    if (event.detail) {
      const select2Element = $(event.detail.picker);
      if (event.detail.value !== undefined) {
        select2Element.val(event.detail.value).trigger('change');
      }
      else if (event.detail.selected) {
        const optionToSelect = select2Element
          .find('option')
          .filter(function () {
            return $(this).text() === event.detail.selected;
          });

        if (optionToSelect.length > 0) {
          const valueToSelect = optionToSelect.val();
          select2Element.val(valueToSelect).trigger('change');
        } else {
          console.warn(`Option "${event.detail.selected}" not found in Select2 picker "${event.detail.picker}"`);
        }
      }
    }
  });
});
