window.addEventListener('DOMContentLoaded', function() {
  // Allow links to switch tabs as well as do their `toggle` method. Helpful for links that need to switch tabs and, say, open a modal there.
  // If the tab is not present on the DOM, the link returns to its default behavior and loads the page corresponding to the `href` attribute
  document.addEventListener('click', (event) => {
    if (!event.target.matches('a[data-tab]')) { return }
    let link = event.target;
    let tabViewSelector = link.dataset.tabView || '.tab-view .nav-tabs';
    let tabTarget = link.dataset.tab;
    let tabTrigger = document.querySelector(`${tabViewSelector} a[href="#${tabTarget}"]`);
    if (tabTrigger) {
      let findModal = () => document.querySelector(`${link.dataset.target}.modal`);
      if (link.dataset.toggle === 'modal' && !findModal()) {
        console.log("Don't trigger!");
        event.stopImmediatePropagation();
        let interval = setInterval(() => {
          if (findModal()) {
            clearInterval(interval);
            if (link.dataset.targetPicker && (link.dataset.targetPickerSelected || link.dataset.targetPickerValue)) {
              link.dispatchEvent(new CustomEvent("select_on_open", {
                bubbles: true,
                cancelable: true,
                detail: {picker: link.dataset.targetPicker, value: link.dataset.targetPickerValue}
              }));
            }
            $(findModal()).modal('show');
          }
        }, 100);
      }
      $(tabTrigger).tab('show');
    } else {
      window.location = link.getAttribute('href');
    }
  });
});
